import { message } from 'antd';
import Papa from 'papaparse'; // CSV parsing library
import axios from 'axios';
import { BASE_URL } from '../../../Config';

const parseCsv = (file, dataType, linkedinProfileId, onNewItem) => {
    const isCSV = file.type === 'text/csv';

    if (!isCSV) {
        message.error('You can only upload CSV files!');
        return false;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
        const csvData = event.target.result;

        Papa.parse(csvData, {
            complete: async (results) => {

                const rows = results.data;

                // skip the first row as header
                for (let i = 1; i < rows.length; i++) {
                    const keyword = rows[i][0]; // can be linkedin profile url, company url or simply a hashtag
                    const progress = Math.round(((i + 1) / rows.length) * 100);

                    if (keyword) {
                        try {

                            let remoteUrl = `${BASE_URL}/api/parse/account`;

                            // Handle according to dataType
                            if (['hashtag', 'locations', 'headline-keywords'].includes(dataType)) {
                                onNewItem(keyword, progress);
                                continue;
                            } else if (dataType === 'linkedin-profile' || dataType === 'profiles-filter' || dataType === 'direct-targets') {
                                remoteUrl = `${BASE_URL}/api/parse/account`;
                            } else if (dataType === 'linkedin-company') {
                                remoteUrl = `${BASE_URL}/api/parse/company`;
                            } else {
                                console.error('Unknown dataType provided for csv parsing', dataType);
                                onNewItem(false, progress);
                                continue;
                            }

                            const response = await axios.post(remoteUrl, {
                                url: keyword,
                                linkedinProfileId: linkedinProfileId
                            });

                            console.log('Response for URL:', keyword, response.data);

                            if (response.data) {
                                onNewItem(response.data, progress);
                            }

                        } catch (error) {
                            console.error('Error fetching profile:', keyword, error);
                            message.error(`Failed to fetch data for ${keyword}`);
                        }
                    }
                }
            },
            header: false, // so that we get columns as indexes instead of named
            skipEmptyLines: true,
        });
    };

    reader.readAsText(file);

    // Prevent default upload behavior
    return false;
};

export default parseCsv;
