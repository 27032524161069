import React, { useState, useEffect } from 'react';
import styles from './DirectTargetSettingsElement.module.scss';
import cc from 'classnames';
import { Checkbox, Input, Row, Col, Select, Tooltip, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const DirectTargetSettingsElement = ({
  onSuccess,
  data
}) => {

  const [likesPerDay, SetLikesPerDay] = useState(0);
  const [maxCommentAge, SetMaxCommentAge] = useState(0);

  const getAllSettings = () => {
    return {
      likesPerDay,
      maxCommentAge
    };
  }

  const onChangeLikesPerDay = (e) => {
    SetLikesPerDay(e.target.value);
    onSuccess({ ...getAllSettings(), likesPerDay: e.target.value });
  };

  const onChangeMaxCommentAge = (e) => {
    SetMaxCommentAge(e.target.value);
    onSuccess({ ...getAllSettings(), maxCommentAge: e.target.value });
  };

  useEffect(() => {
    if (data) {
      SetLikesPerDay(data?.likesPerDay);
      SetMaxCommentAge(data?.maxCommentAge);
    }
  }, [data]);

  return (
    <div className={cc(styles.custom_tags_container)}>
      <div className={styles.header_details_container}>
        <div className={styles.title}>
          <h6>Direct Target Settings</h6>
        </div>
      </div>
      <div className={styles.tags_container}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Row>
            <Col span={12}>
              Max. Likes Per Day Per Direct Target
            </Col>
            <Col span={12}>
              <Input
                className={styles.flex_equal}
                size="small"
                value={likesPerDay}
                onChange={onChangeLikesPerDay}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              Max. Comment Age (days)
            </Col>
            <Col span={12}>
              <Input
                className={styles.flex_equal}
                size="small"
                value={maxCommentAge}
                onChange={onChangeMaxCommentAge}
              />
            </Col>
          </Row>
        </Space>
      </div>

    </div>
  );
};

export default DirectTargetSettingsElement;
